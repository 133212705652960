import {Component, Input, OnInit} from '@angular/core';
import {ExecuteLoadAddOrUpdate, Meter, PowerSupply} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {MeterService} from '../../../meter/service/meter.service';
import {LoadService} from '../../service/load.service';
import {AuthService} from "../../../../shared/guards/auth.service";

const DAY = 24 * 60 * 60 * 1000;

@Component({
    selector: 'app-edit-load-dialog',
    templateUrl: './edit-load-dialog.component.html',
    styleUrls: ['./edit-load-dialog.component.scss']
})
export class EditLoadDialogComponent implements IEditForm<ExecuteLoadAddOrUpdate>, OnInit {

    @Input()
    public possiblePowerSupplies!: PowerSupply[];
    @Input()
    public data!: ExecuteLoadAddOrUpdate;
    @Input()
    public edit!: boolean;

    public meters!: BehaviorSubject<Meter[] | undefined>;
    public isDev: boolean;

    public constructor(
        private readonly meterService: MeterService,
        private readonly authService: AuthService
    ) {
        this.isDev = authService.isDeveloper();
    }

    public ngOnInit(): void {
        this.meters = this.meterService.getAll();
    }

    public validate(): string[] {
        if (this.data.type === "MeterBasedDynamicLoad" && (this.data.meterId === undefined || this.data.meterId === 0)) {
            return ["Kein Messgerät ausgewählt"];
        }
        return [];
    }

    public updateGraphLen(): void {
        switch (this.data.repeatInterval) {
            case 'DAY':
                this.data.graphPointList = this.data.graphPointList.filter(v => v.tst < DAY);
                break;
            case 'WEEK':
                for(let day = 0; day < 7; day++){
                    if(!this.hasMidnightBefore(day)) {
                        this.data.graphPointList.push({
                            tst: day * DAY,
                            l1: 300, l2: 300, l3: 300
                        });
                    }
                    if(!this.hasDataForDay(day)) {
                        this.data.graphPointList.push(...LoadService.generateDefaultGraphPoints(day, false));
                    }
                }
        }
    }

    private hasMidnightBefore(day: number): boolean {
        return this.data.graphPointList.some(v => v.tst === (day * DAY));
    }

    private hasDataForDay(day: number): boolean {
        return this.data.graphPointList.some(v => v.tst > (day * DAY)  && v.tst < ((day + 1) * DAY));
    }
}

import {Injectable} from '@angular/core';
import {
    ExecuteLoadAddOrUpdate,
    GraphPoint,
    Load,
    LoadHistory, LoadLiveDataList,
    PowerSupply,
    TimeZoneName
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {EditLoadDialogComponent} from '../dialogs/edit-load-dialog/edit-load-dialog.component';
import {num} from '../../../shared/helper/util-functions';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {CacheUpdater} from "../../../services/api-handlers/cacheManager";
import {BehaviorSubject} from "rxjs";
import {POLL_INTERVALS} from "../../../app.module";

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

@Injectable({
    providedIn: 'root'
})
export class LoadService extends DialogHandler<Load, ExecuteLoadAddOrUpdate, ExecuteLoadAddOrUpdate, {}, PowerSupply[], PowerSupply[]> {
    private loadLiveDataCache!: CacheUpdater<LoadLiveDataList, number>;


    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "Load", toastr, dialog, dialogService, POLL_INTERVALS.loads);
        this.loadLiveDataCache = this.createManagedCache(() => true);
    }

    public static generateDefaultGraphPoints(dayOffset: number, generateMidnightBefore: boolean): GraphPoint[] {
        const offset = dayOffset * DAY;
        return [
            {
                tst: generateMidnightBefore ? 0 : 3 * HOUR,
                l1: 700, l2: 500, l3: 400
            }, {
                tst: 6*HOUR,
                l1: 700, l2: 500, l3: 400
            }, {
                tst: 6 * HOUR + 15 * MINUTE,
                l1: 7000, l2: 5000, l3: 4000
            }, {
                tst: 18 * HOUR,
                l1: 7000, l2: 5000, l3: 4000
            }, {
                tst: 18 * HOUR + 15 * MINUTE,
                l1: 700, l2: 500, l3: 400
            }, {
                tst: 21 * HOUR,
                l1: 700, l2: 500, l3: 400
            }
        ].map(v => {
            return {
                l1: v.l1, l2: v.l2, l3: v.l3,
                tst: v.tst+offset
            }
        });
    }

    // tslint:disable-next-line:max-line-length
    protected getEditConfig(load: Load, powerSupplies: PowerSupply[]): TDialogOptions<ExecuteLoadAddOrUpdate, EditLoadDialogComponent> {
        return {
            headline: "Last bearbeiten",
            component: EditLoadDialogComponent,
            executeCallback: editResult => this.update(load.id, editResult),
            editElement: {
                name: load.name ||"",
                type: load.type ||"MeterBasedDynamicLoad",
                powerSupplyId: num(load.powerSupplyId, powerSupplies[0].id),
                meterId: load.meterId || 0,
                repeatInterval: load.repeatInterval || ExecuteLoadAddOrUpdate.RepeatIntervalEnum.Day,
                graphPointList: load.graphPointList.length > 0 ? load.graphPointList : LoadService.generateDefaultGraphPoints(0, true),
                formula: load.formula,
                zone: load.zone || Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneName
            },
            extraParams: {
                edit: true,
                possiblePowerSupplies: powerSupplies
            }
        }
    }

    protected getNewConfig(powerSupplies: PowerSupply[]): TDialogOptions<ExecuteLoadAddOrUpdate, EditLoadDialogComponent> {
        return {
            headline: "Last anlegen",
            component: EditLoadDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "neue Last",
                type: "MeterBasedDynamicLoad",
                powerSupplyId: powerSupplies[0].id,
                meterId: 0.0,
                repeatInterval: ExecuteLoadAddOrUpdate.RepeatIntervalEnum.Day,
                graphPointList: LoadService.generateDefaultGraphPoints(0, true),
                formula: "+ C 1500 1500 1500",
                zone: Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneName
            },
            extraParams: {
                edit: false,
                possiblePowerSupplies: powerSupplies
            }
        }
    }

    public getHistory(load: Load, showAlerts = true): Promise<LoadHistory> {
        return this.apiService.getLoadHistory(showAlerts, load.id, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getLiveData(showAlerts = true): BehaviorSubject<LoadLiveDataList | undefined> {
        return this.loadLiveDataCache.getOrCreateGet(0, () => this.apiService.getLoadLiveData(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    public async regenerateLoadHistory(load: Load, showAlerts = true): Promise<{}> {
        return this.apiService.regenerateLoadHistory(showAlerts, load.id, undefined, undefined, ApiHandler.customerId).toPromise();
    }
}

<div *ngIf="(permissionGroups | async) as permissionGroups; else loading">
    <form [formGroup]="formGroup">
        <div class="d-flex flex-column gap-3">
            <div class="d-flex flex-row gap-3">
                <mat-form-field appearance="fill" class="flex-fill">
                    <mat-label>Benutzername</mat-label>
                    <input matInput placeholder="Max" formControlName="userNameCtrl">
                    <mat-error *ngIf="formGroup.get('userNameCtrl')?.errors?.userExists as error">{{error}}</mat-error>
                    <mat-error *ngIf="formGroup.get('userNameCtrl')?.errors?.usernameInvalid as error">{{error}}</mat-error>
                    <mat-error *ngIf="formGroup.get('userNameCtrl')?.errors?.required as error">{{error}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="flex-fill">
                    <mat-label>Passwort</mat-label>
                    <input matInput type="password" placeholder="S3cr3t" formControlName="passwordCtrl">
                    <mat-error *ngIf="formGroup.get('passwordCtrl')?.errors?.passwordRequired as error">{{error}}</mat-error>
                </mat-form-field>
            </div>
            <div class="d-flex flex-row gap-3">
                <mat-form-field appearance="fill" class="flex-fill">
                    <mat-label>Vorname</mat-label>
                    <input  matInput placeholder="Maximilian" formControlName="firstNameCtrl">
                </mat-form-field>
                <mat-form-field appearance="fill" class="flex-fill">
                    <mat-label>Nachname</mat-label>
                    <input matInput placeholder="Petersen" formControlName="lastNameCtrl">
                </mat-form-field>
            </div>
            <div class="d-flex flex-row gap-3">
                <mat-form-field appearance="fill" class="flex-fill">
                    <mat-label>E-Mail Adresse</mat-label>
                    <input matInput placeholder="Petersen" formControlName="mailCtrl" >
                    <mat-error *ngIf="formGroup.get('mailCtrl')?.errors?.mailInvalid as error">{{error}}</mat-error>
                    <mat-error *ngIf="formGroup.get('mailCtrl')?.errors?.mailExist as error">{{error}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="flex-fill">
                    <mat-label>Berechtigungsgruppen</mat-label>
                    <mat-select formControlName="permissionCtrl" multiple>
                        <mat-option *ngFor="let pG of permissionGroups; trackBy: trackById" [value]="pG.id">{{pG.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="d-flex flex-row gap-3">
                <mat-form-field appearance="fill" class="flex-grow-1">
                    <mat-label>Level für Benachrichtigungen</mat-label>
                    <mat-select formControlName="notificationLevelCtrl">
                        <mat-option [value]="0">Aus</mat-option>
                        <mat-option [value]="200">Fehler</mat-option>
                        <mat-option [value]="300">Warnungen</mat-option>
                        <mat-option [value]="400">Info</mat-option>
                        <mat-option *ngIf="isDeveloper" [value]="500">Alles (Auch Debug Zeug)</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="w-50 flex-grow-0"><!-- Just a spacer --></div>
            </div>
            <mat-checkbox formControlName="sendReservationMailsCtrl">
                E-Mails zu Reservierungen schicken
            </mat-checkbox>
            <mat-checkbox formControlName="welcomeMailCtrl">E-Mail mit Link zum Setzen des Passworts versenden</mat-checkbox>
        </div>
    </form>
</div>
<ng-template #loading>Loading...</ng-template>

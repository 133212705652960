<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Angeschlossen an:</mat-label>
            <mat-select [(value)]="data.powerSupplyId">
                <mat-option *ngFor="let p of possiblePowerSupplies" [value]="p.id">{{p.name}} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Typ:</mat-label>
            <mat-select [(value)]="data.type" [disabled]="edit">
                <mat-option [value] ="'MeterBasedDynamicLoad'">Meter</mat-option>
                <mat-option [value] ="'UserDefinedLoad'">Nutzerdefiniert</mat-option>
                <mat-option [value] ="'FormulaLoad'">Formel</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="data.type === 'UserDefinedLoad'" appearance="fill">
            <mat-label>Wiederholungsintervall:</mat-label>
            <mat-select [(value)]="data.repeatInterval" (valueChange)="updateGraphLen()">
                <mat-option [value] ="'DAY'">Tag</mat-option>
                <mat-option [value] ="'WEEK'">Woche</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="data.type === 'MeterBasedDynamicLoad' && (meters | async) as meters" appearance="fill">
            <mat-label>Messgerät:</mat-label>
            <mat-select [(value)]="data.meterId">
                <mat-option *ngFor="let m of meters" [value]="m.id">{{m.name}} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="data.type === 'UserDefinedLoad' && data.repeatInterval === 'WEEK'">
        <mat-tab-group>
            <mat-tab label="Montag">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="0" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
            <mat-tab label="Dienstag">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="1" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
            <mat-tab label="Mittwoch">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="2" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
            <mat-tab label="Donnerstag">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="3" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
            <mat-tab label="Freitag">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="4" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
            <mat-tab label="Samstag">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="5" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
            <mat-tab label="Sontag">
                <ng-template matTabContent>
                    <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="6" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div *ngIf="data.type === 'UserDefinedLoad' && data.repeatInterval === 'DAY'">
        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="0" dayCount="1" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
        <mat-hint>Zu hohe Annahmen können tatsächliches Einsparpotenzial verringern. Zu niedrige Annahmen können zu einer Überlastung des Anschlusses führen.</mat-hint>
    </div>
    <div *ngIf="data.type === 'UserDefinedLoad'">
        <mat-hint>Zeitzone: {{data.zone}}</mat-hint>
    </div>
    <ng-container *ngIf="data.type === 'FormulaLoad'">

        <app-edit-formula [(formula)]="data.formula" [name]="data.name"></app-edit-formula>
        <div *ngIf="isDev" style="width:100%">
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Formel</mat-label>
                <input matInput [(ngModel)]="data.formula">
            </mat-form-field>
        </div>
    </ng-container>
</div>

<h1 id="page-title">Einstellungen</h1>
<div class="d-flex flex-column gap-3">
    <mat-card appearance="outlined">
        <mat-card-title>Password Settings</mat-card-title>
        <mat-card-content>
            Hier können Sie ihr Passwort ändern. Zum Schutz Ihres IO-ELON Accounts erhalten Sie eine E-Mail über die Sie ein neues Passwort vergeben können.
            <br>
            <button [disabled]="!hasEmail()" matTooltip="Deaktiviert falls keine Email für ihrem Account gespeichert ist" (click)="requestPasswordChange()" mat-raised-button>Password ändern</button>
            <br>
            <a *ngIf="!hasEmail()">Zum Nutzen dieser Funktion, müssen Sie zuerst eine E-Mail zu ihrem Account hinzufügen.</a>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
        <mat-card-title>E-Mail Einstellungen</mat-card-title>
        <mat-card-content>
            <mat-form-field appearance="fill">
                <mat-label>E-Mail Adresse</mat-label>
                <input matInput placeholder="Petersen" [(ngModel)]="updateEmailSettings.email">
            </mat-form-field><br/>
            <mat-form-field appearance="fill">
                <mat-label>Level für Benachrichtigungen</mat-label>
                <mat-select [(ngModel)]="updateEmailSettings.emailAlertLevel">
                    <mat-option [value]="0">Aus</mat-option>
                    <mat-option [value]="200">Fehler</mat-option>
                    <mat-option [value]="300">Warnungen</mat-option>
                    <mat-option [value]="400">Info</mat-option>
                    <mat-option *ngIf="isDeveloper" [value]="500">Alles (Auch Debug Zeug)</mat-option>
                </mat-select>
            </mat-form-field><br>
            <mat-checkbox [(ngModel)]="updateEmailSettings.sendReservation">E-Mails zu Reservierungen schicken</mat-checkbox><br><br>

            <button (click)="saveEmailSettings()" mat-raised-button>Speichern</button>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
        <mat-card-title>Anzeige Einstellungen</mat-card-title>
        <mat-card-content>
            <mat-form-field appearance="fill">
                <mat-label>Reihenfolge Fahrzeuge in Flotte</mat-label>
                <mat-select [(ngModel)]="vehicleDefaultSortOrder">
                    <mat-option [value]="'name'">Name</mat-option>
                    <mat-option [value]="'numberPlate'">Nummernschild</mat-option>
                    <mat-option [value]="'localId'">Lokale ID</mat-option>
                    <mat-option [value]="'id'">ID</mat-option>
                </mat-select>
            </mat-form-field><br>
            <mat-form-field *ngIf="isDeveloper || showDevWebRequests === 'true'" appearance="fill">
                <mat-label>Web-Requests anzeigen</mat-label>
                <mat-select [(ngModel)]="showDevWebRequests">
                    <mat-option [value]="'true'">Ja</mat-option>
                    <mat-option [value]="'false'">Nein</mat-option>
                </mat-select>
            </mat-form-field><br>
            <mat-form-field *ngIf="isDeveloper || pollEnabled === 'false'" appearance="fill">
                <mat-label>Daten Pollen</mat-label>
                <mat-select [(ngModel)]="pollEnabled">
                    <mat-option [value]="'true'">Ja</mat-option>
                    <mat-option [value]="'false'">Nein</mat-option>
                </mat-select>
            </mat-form-field><br>
            <mat-form-field appearance="fill">
                <mat-label>Anzahl Einträge in Listen</mat-label>
                <mat-select [(ngModel)]="defaultListEntriesCount">
                    <mat-option [value]="'10'">10</mat-option>
                    <mat-option [value]="'20'">20</mat-option>
                    <mat-option [value]="'25'">25</mat-option>
                    <mat-option [value]="'30'">30</mat-option>
                    <mat-option [value]="'40'">40</mat-option>
                    <mat-option [value]="'50'">50</mat-option>
                </mat-select>
            </mat-form-field><br>
            <mat-form-field appearance="fill">
                <mat-label>Anzahl Fahrzeuge, ab der Animationen im Ladeplan abgeschaltet werden</mat-label>
                <mat-select [(ngModel)]="chargePlanAnimationVehicleLimit">
                    <mat-option [value]="'off'">Immer abschalten</mat-option>
                    <mat-option [value]="'5'">5</mat-option>
                    <mat-option [value]="'10'">10 (Langsame PCs)</mat-option>
                    <mat-option [value]="'20'">20</mat-option>
                    <mat-option [value]="'30'">30 (Schnelle PCs)</mat-option>
                    <mat-option [value]="'on'">Immer einschalten</mat-option>
                </mat-select>
            </mat-form-field><br>
        </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
        <mat-card-title>Entwicklung</mat-card-title>
        <mat-card-content>
            <button (click)="setShowDevInfo(false)" mat-raised-button>Dev Infos abschalten</button>
            <button (click)="setShowDevInfo(true)" mat-raised-button>Dev Infos einschalten</button>
            <br>
            <span class="mat-caption">Für einige Optionen ist ein Neuladen der Seite notwendig</span><br>
            <span>Aktueller Status: <b>{{isDevInfo()}}</b></span>
        </mat-card-content>
    </mat-card>
</div>

import {Component} from '@angular/core';
import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AbstractUserDialogDirective} from '../abstract-user-dialog.directive';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent extends AbstractUserDialogDirective {

    protected setUpFromGroup() {
        super.setUpFromGroup();
        const passwordCtrl = new FormControl(this.data.password ?? null);
        passwordCtrl.valueChanges.subscribe(() => this.data.password = passwordCtrl.value!);

        const welcomeMailCtrl = new FormControl(this.data.sendWelcome ?? null);
        welcomeMailCtrl.valueChanges.subscribe(() => this.data.sendWelcome = welcomeMailCtrl.value!);

        const mailCtrl: FormControl<string | null> = this.formGroup.get('mailCtrl') as FormControl<string | null>;
        const notificationLevelCtrl = this.formGroup.get("notificationLevelCtrl") as FormControl<number | null>;
        const sendReservationMailsCtrl = this.formGroup.get("sendReservationMailsCtrl") as FormControl<boolean | null>;

        this.formGroup.setValidators([
            this.checkEmailAlertLevel(mailCtrl, notificationLevelCtrl),
            this.checkEmailReservation(mailCtrl, sendReservationMailsCtrl),
            this.checkPassword(welcomeMailCtrl, passwordCtrl, mailCtrl),
        ]);

        this.formGroup.addControl('passwordCtrl', passwordCtrl);
        this.formGroup.addControl('welcomeMailCtrl', welcomeMailCtrl);
    }

    private checkPassword(welcomeMailField: FormControl<boolean | null>, passwordField: FormControl<string | null>, mailField: FormControl<string | null>): ValidatorFn {
        return (): ValidationErrors | null => {
            if (!(welcomeMailField.value || passwordField.value)) {
                return {passwordRequired: 'Passwort nicht ausgefüllt'};
            } else if (!mailField.value && welcomeMailField.value) {
                return {mailForWelcomeRequired: 'Zum senden der Willkommens-E-Mail, muss eine E-Mail gespeichert werden'};
            } else {
                return null;
            }
        };
    }
}

<div *ngIf="!parseError; else parseErrorMsg" style="min-width: 700px">
    <div class="row" style="padding: 10px">
        <h2>{{name}} =</h2>
    </div>
    <ng-container *ngFor="let t of terms; let idx=index;">

        <div class="row" [class.editActive]="idx === editIdx">
            <div class="operation" >
                <i *ngIf="t.operation === '+'" style="color: green" class="fa-solid fa-plus"></i>
                <i *ngIf="t.operation === '-'" style="color: orangered" class="fa-solid fa-minus"></i>
            </div>
            <ng-container [ngSwitch]="t.type">
                <ng-container *ngSwitchCase="'E'">
                    <div class="name">Ladepunkt: <app-evse-link *ngIf="t.args.id !== -1; else choose" [evseId]="t.args.id"></app-evse-link></div>
                    <div class="arg"></div>
                </ng-container>
                <ng-container *ngSwitchCase="'M'">
                    <div class="name">Zähler: <span *ngIf="t.args.id !== -1; else choose">{{meterName(t.args.id)}}</span></div>
                    <div class="arg">
                        <img *ngIf="t.args.rotation !== 'NO_ROTATION'" [src]="rotationImage(t.args.rotation)" [alt]="t.args.rotation">
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'L'">
                    <div class="name">Last: <span *ngIf="t.args.id !== -1; else choose">{{loadName(t.args.id)}}</span></div>
                    <div class="arg">
                        <img *ngIf="t.args.rotation !== 'NO_ROTATION'" [src]="rotationImage(t.args.rotation)" [alt]="t.args.rotation">
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'C'">
                    <div class="name">Konstante: </div>
                    <div class="arg">L1: {{t.args.p1}}W, L2: {{t.args.p2}}W, L3: {{t.args.p3}}W</div>
                </ng-container>
            </ng-container>
            <div style="flex-grow: 2"></div>
            <button class="btn btnEdit" mat-icon-button matTooltip="Bearbeiten" (click)="edit(idx)">
                <i class="fas fa-edit"></i>
            </button>
            <button *ngIf="removeIdx!==idx" class="btn btnEdit" mat-icon-button matTooltip="Löschen" (click)="remove1(idx)">
                <i class="fas fa-trash"></i>
            </button>
            <button *ngIf="removeIdx===idx" class="btn btnEdit" mat-icon-button color="warn" matTooltip="Löschen bestätigen!" (click)="remove2(idx)">
                <i class="fas fa-trash"></i>
            </button>
        </div>
        <span *ngIf="idx !== editIdx; else editor"></span>
    </ng-container>
    <span class="newWrapper">
        <button *ngIf="editIdx !== -1" class="btn btnAdd" mat-raised-button color="primary" matTooltip="Neuer Term" (click)="edit(-1)">
            <i class="fas fa-square-plus"></i>
        </button>
        <ng-container *ngIf="editIdx === -1">
            <ng-container *ngTemplateOutlet="editor; context: {showBtn: true}"></ng-container>
        </ng-container>
    </span>
</div>

<ng-template #choose>
    Bitte wählen
</ng-template>

<ng-template #parseErrorMsg>
    Fehler beim interpretieren der Formel:<br>
    {{formula}}
</ng-template>

<ng-template #editor let-showBtn="showBtn">
    <div class="editor" [style.height]="editorHeight">
        <mat-form-field appearance="fill" class="editOperation">
            <mat-label>Operation</mat-label>
            <mat-select [(value)]="editTerm.operation" (selectionChange)="emitTerms()">
                <mat-option [value]="'+'">+</mat-option>
                <mat-option [value]="'-'">-</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="editType">
            <mat-label>Typ</mat-label>
            <mat-select [(value)]="editTerm.type" (selectionChange)="resetId(); emitTerms()">
                <mat-option [value]="'E'">EVSE</mat-option>
                <mat-option [value]="'M'">Smartmeter</mat-option>
                <mat-option [value]="'L'">Last</mat-option>
                <mat-option [value]="'C'">Konstante</mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container [ngSwitch]="editTerm.type">
            <ng-container *ngSwitchCase="'E'">
                <mat-form-field appearance="fill" class="editEvse" *ngIf="(allEvses | async) as allEvses; else loading">
                    <mat-label>Ladepunkt</mat-label>
                    <mat-select [(value)]="editTerm.args.id" (selectionChange)="emitTerms()">
                        <mat-option [value]="-1">Bitte wählen</mat-option>
                        <mat-option *ngFor="let e of filterAndSortEvses(allEvses)" [value]="e.id">{{e.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="'M'">
                <mat-form-field appearance="fill" class="editMeter" *ngIf="(allMeters | async) as allMeters; else loading">
                    <mat-label>Smartmeter</mat-label>
                    <mat-select [(value)]="editTerm.args.id" (selectionChange)="emitTerms()">
                        <mat-option [value]="-1">Bitte wählen</mat-option>
                        <mat-option *ngFor="let m of filterAndSortMeter(allMeters)" [value]="m.id">{{m.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="phaseRotation"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'L'">
                <mat-form-field appearance="fill" class="editLoad" *ngIf="(allLoads | async) as allLoads; else loading">
                    <mat-label>Last</mat-label>
                    <mat-select [(value)]="editTerm.args.id" (selectionChange)="emitTerms()">
                        <mat-option [value]="-1">Bitte wählen</mat-option>
                        <mat-option *ngFor="let l of allLoads" [value]="l.id">{{l.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="phaseRotation"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'C'">
                <mat-form-field appearance="fill" class="editPwr">
                    <mat-label>L1 Leistung (W)</mat-label>
                    <input matInput [(ngModel)]="editTerm.args.p1" (change)="emitTerms()">
                </mat-form-field>
                <mat-form-field appearance="fill" class="editPwr">
                    <mat-label>L2 Leistung (W)</mat-label>
                    <input matInput [(ngModel)]="editTerm.args.p2" (change)="emitTerms()">
                </mat-form-field>
                <mat-form-field appearance="fill" class="editPwr">
                    <mat-label>L3 Leistung (W)</mat-label>
                    <input matInput [(ngModel)]="editTerm.args.p3" (change)="emitTerms()">
                </mat-form-field>
            </ng-container>
        </ng-container>
        <button *ngIf="showBtn" class="btn btnCommit" mat-raised-button color="primary" matTooltip="Übernehmen" (click)="commit()">
            <i class="fas fa-save"></i>
        </button>
    </div>
</ng-template>

<ng-template #loading>
    Laden...
</ng-template>

<ng-template #phaseRotation>
    <mat-form-field appearance="fill" class="editPhases">
        <mat-label>Phasenzuordnung</mat-label>
        <mat-select [(ngModel)]="editTerm.args.rotation" (selectionChange)="emitTerms()">
            <mat-option [value]="'NO_ROTATION'">Keine Phasenänderung</mat-option>
            <mat-option [value]="'LEFT'">Links rotiert</mat-option>
            <mat-option [value]="'RIGHT'">Rechts rotiert</mat-option>
            <mat-option [value]="'SWITCH_1_2'">L1 und L2 getauscht</mat-option>
            <mat-option [value]="'SWITCH_1_3'">L1 und L3 getauscht</mat-option>
            <mat-option [value]="'SWITCH_2_3'">L2 und L3 getauscht</mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>


import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ExecuteUserAddOrUpdate, User} from '@io-elon-common/frontend-api';
import {UserService} from '../../service/user.service';
import {read, utils, WorkBook, WorkSheet} from 'xlsx';
import {downloadAsset} from '../../../../shared/helper/util-functions';
import {AuthService} from '../../../../shared/guards/auth.service';


@Component({
    selector: 'app-user-list-view',
    templateUrl: './user-list-view.component.html',
    styleUrls: ['./user-list-view.component.scss']
})
export class UserListViewComponent implements OnInit {
    public users!: BehaviorSubject<User[] | undefined>;
    public newUsers!: ExecuteUserAddOrUpdate[];

    public constructor(
        private readonly userService: UserService,
        private readonly authService: AuthService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.users = this.userService.getAll();
        this.newUsers = [];
    }

    public async newUser() {
        await this.userService.showNewDialog();
    }

    public async downloadTemplate() {
        downloadAsset("io_elon_users.xlsx", "assets/io_elon_users.xlsx")
    }

    public async handleFileInput(files: FileList) {
        if(files.length !== 1) return;

        const file = files.item(0);
        if(file === null) return;

        file.arrayBuffer().then((ab: ArrayBuffer)=> {
            const wb: WorkBook = read(ab);
            const wsname: string = wb.SheetNames[0];
            const ws: WorkSheet = wb.Sheets[wsname];

            const data = (utils.sheet_to_json<Array<any>>(ws, {header: 1}));
            this.newUsers = data.slice(2).filter(value => value[1]!==undefined).map((value: Array<any>) => this.xlsxRowToNewUser(value));

        });

    }
    public uploadInProgress(): boolean {
        return this.newUsers.length > 0;
    }
    public cancelUpload() {
        this.newUsers = [];
    }

    allowedToAddUsers(): boolean{
        return this.authService.hasGlobalPermission("ADD_USERS");
    }

    private notificationLevel(level: string): number|undefined {
        switch (level) {
            case "Aus": return 0
            case "Fehler": return 200;
            case "Warnung": return 300;
            case "Info": return 400;
            default: return undefined;
        }
    }
    private xlsxRowToNewUser(row: Array<any>): ExecuteUserAddOrUpdate {
        // Header: ['User', 'Benutzername', 'Passwort', 'Vorname', 'Nachname', 'E-Mail', 'Benachrichtigungs Level', 'E-Mails zu Reservierungen schicken', 'E-Mail mit Link zum Setzen des Passwort']
        // (9) ['Beispiel', 'Max', 1234, 'Max', 'Musterman', 'Max.Musterman@email.com', 'Fehler', 'Ja', 'Ja']
        return {
            name: row[1] || '',
            password: row[2] || '',
            firstname: row[3] || '',
            lastname: row[4] || '',
            email: row[5] || '',
            emailAlertLevel: this.notificationLevel(row[6]),
            sendReservation: row[7] === "Ja",
            sendWelcome: row[8] === "Ja",
            permissionGroups: []
        }
    }
}

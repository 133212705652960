import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    GraphRange,
    HistoryCellData
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import Annotation = dygraphs.Annotation;
import Options = dygraphs.Options;
import { EvseService } from "../../service/evse.service";
import { ApiService } from "../../../../services/api-handlers/api.service";
import { EventLogEntry, EvseHistoryData } from '@io-elon-common/frontend-api';
import { ApiHandler } from "../../../../services/api-handlers/api-handler";
import { PowerUnits } from 'src/app/shared/helper/power-units';

const HOUR = 3600 * 1000;
const DAY = 24 * HOUR;
const SHOW_EVENT_MAX_TIME = 3 * DAY;


const COLOR_CHARGING = 'rgb(255,0,0)';
const COLOR_PLUGGED = 'rgba(200, 200, 200, 0.5)';
const COLOR_CHARGING_PLUGGED = 'rgba(255, 0, 0, 1)';
const COLOR_ALLOWED = 'rgba(50, 50, 255, 1)';
const COLOR_ALLOWED_CHARGING = 'rgba(255, 0, 0, 1)';
const COLOR_ALLOWED_PLUGGED = 'rgba(150, 150, 150, 1)';
const COLOR_ALLOWED_PLUGGED_CHARGING = 'rgba(76, 177, 227, 0.5)';
const COLOR_ERROR = 'rgba(255, 0, 0, 1)';

interface IEvseGraphData {
    data: Array<Array<HistoryCellData>>;
    background: Array<GraphRange & { type: number }>;
    events: Annotation[];
}

@Component({
    selector: 'app-evse-history-graph',
    templateUrl: './evse-history-graph.component.html',
    styleUrls: ['./evse-history-graph.component.scss']
})
export class EvseHistoryGraphComponent extends AbstractHistoryGraph<IEvseGraphData> implements OnInit {

    @Input() evseId!: number;
    @Input() autoReload!: boolean
    @Input() selectedUnit!: PowerUnits;
    @Output() autoReloadChange = new EventEmitter<boolean>();

    private maxY2?: Promise<number>;

    constructor(
        private readonly element: ElementRef,
        private readonly apiService: ApiService,
        private readonly evseService: EvseService
    ) {
        super();
    }

    protected async getMaxY2(): Promise<number> {
        if (!this.maxY2) {
            this.maxY2 = this.evseService.getPromise(this.evseId).then(evse => evse.modelMaxAmps || 32);
        }
        return this.maxY2;
    }

    private createData(start: number, end: number, data: EvseHistoryData, filteredLogEvents: Array<EventLogEntry & { multiple?: boolean }>): HistoryCellData[][] {
        const gapDistance = (end - start) / 2;
        let d;
        let indexCount = 0;
        if (this.selectedUnit === PowerUnits.W) {
            const pDataRaw = this.sumGraphLines(data.evseP1, data.evseP2, data.evseP3);
            d = this.mapToArray(pDataRaw, 'time', 'val', 'min', 'max', 0, 3, undefined, gapDistance);
        } else {
            const i1 = this.mapToArray(data.evseI1, 'time', 'val', 'min', 'max', 0, 5, undefined, gapDistance);
            const i2 = this.mapToArray(data.evseI2, 'time', 'val', 'min', 'max', 1, 5, undefined, gapDistance);
            const i3 = this.mapToArray(data.evseI3, 'time', 'val', 'min', 'max', 2, 5, undefined, gapDistance);
            d = i1.concat(i2).concat(i3);
            indexCount = 2;
        }

        const eventLogSeries = this.mapToArray(filteredLogEvents, 'tst', 'id', undefined, undefined, indexCount + 1, indexCount + 3, () => 0, 0);
        const icp = this.mapToArray(data.evseICp, 'time', 'val', 'min', 'max', indexCount + 2, indexCount + 3, i => this.convertPowerUnits(i, 3, PowerUnits.A, this.selectedUnit), gapDistance);

        if (this.selectedUnit == PowerUnits.W) {
            eventLogSeries.push([new Date(start), null, -1, null]);
            eventLogSeries.push([new Date(end), null, -1, null]);
        } else {
            eventLogSeries.push([new Date(start), null, null, null, -1, null]);
            eventLogSeries.push([new Date(end), null, null, null, -1, null]);
        }

        return d.concat(eventLogSeries).concat(icp);
    }

    protected async loadData(start: number, end: number): Promise<IEvseGraphData> {
        const historyOfEvseRequest = this.evseService.getHistoryOfEvse(this.evseId, start, end);
        let eventLogRequest;

        if (end - start < SHOW_EVENT_MAX_TIME) {
            eventLogRequest = this.apiService.getEvseEvents(true, this.evseId, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
        } else {
            // #1181 No events if zoomed out.
            eventLogRequest = Promise.resolve({
                start,
                end,
                events: ([] as EventLogEntry[])
            });
        }

        const eventLog = await eventLogRequest;
        const data = await historyOfEvseRequest;

        const filteredLogEvents = this.chunkEvents(end, start, eventLog);

        const allowedSlots = this.boolHistoryToAreas(data.data.evseAlw as any);
        const chargingSlots = this.boolHistoryToAreas(data.data.evseCharging as any);
        const pluggedSlots = this.boolHistoryToAreas(data.data.evsePlugged as any);
        const background = this.calcBackgroundSlots(allowedSlots, pluggedSlots, chargingSlots);

        const dataMapped = this.createData(start, end, data.data, filteredLogEvents);
        // @ts-ignore
        dataMapped.sort((a, b) => a[0] - b[0]);
        const dataJoined = this.joinLines(dataMapped);

        const events: Annotation[] = filteredLogEvents.map((e) => {
            return {
                series: 'Events',
                x: e.tst,
                shortText: e.key,
                text: e.key + ': ' + e.message,
                // attachAtBottom: true,
                tickHeight: -2,
                icon: e.multiple ? '/img/markerplus.png' : '/img/marker.png',
                height: 16,
                width: 16
            };
        });

        return {
            data: dataJoined,
            events,
            background
        };
    }

    protected async getConfig(): Promise<Options> {
        return {
            errorBars: true,
            customBars: true,
            axes: {
                y: {
                    valueRange: [0, this.convertPowerUnits(await this.getMaxY2(), 3, PowerUnits.A, this.selectedUnit)],
                    axisLabelFormatter: (v: number | Date) => '<span style="color: orange">' + this.getYValues(v) + '</span>'
                }
            },
            labels: this.getLegendLabels(),
            colors: this.getGraphColors(),
            series: {
                "Strom I1 (A)": {
                    stepPlot: true
                },
                "Strom I2 (A)": {
                    stepPlot: true
                },
                "Strom I3 (A)": {
                    stepPlot: true
                },
                "ICP (A)": {
                    stepPlot: true
                },
                "Leistung (W)": {
                    stepPlot: true
                },
                "ICP (W)": {
                    stepPlot: true
                }
            },
            underlayCallback: (canvas, area, graph) => {
                this.data.background.forEach(s => {
                    switch (s.type) {
                        case 0:
                            return;
                        case 1:
                            this.drawAreaRangesHatched(canvas, area, graph, [s], COLOR_CHARGING);
                            return;
                        case 2:
                            this.drawAreaRangesFilled(canvas, area, graph, [s], COLOR_PLUGGED);
                            return;
                        case 3:
                            this.drawAreaRangesHatchedReversed(canvas, area, graph, [s], COLOR_CHARGING_PLUGGED);
                            return;
                        case 4:
                            this.drawAreaRangesHatched(canvas, area, graph, [s], COLOR_ALLOWED);
                            return;
                        case 5:
                            this.drawAreaRangesHatchedReversed(canvas, area, graph, [s], COLOR_ALLOWED_CHARGING);
                            this.drawAreaRangesHatched(canvas, area, graph, [s], COLOR_ALLOWED_CHARGING);
                            return;
                        case 6:
                            this.drawAreaRangesHatched(canvas, area, graph, [s], COLOR_ALLOWED_PLUGGED);
                            return;
                        case 7:
                            this.drawAreaRangesHatched(canvas, area, graph, [s], COLOR_ALLOWED_PLUGGED_CHARGING);
                            return;
                        default:
                            this.drawAreaRangesFilled(canvas, area, graph, [s], COLOR_ERROR);
                    }
                });
                const nowX = graph.toDomXCoord(Date.now());
                if (nowX > -10 && nowX < area.w) {
                    this.drawNowMarker(canvas, nowX, area.y, 2, area.h);
                }
            },
        };
    }

    public getBackgroundLegend(): BackgroundLegend[] {
        return [
            {
                name: "Laden", // Typ 1
                visible: false,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, COLOR_CHARGING)
            }, {
                name: "Eingesteckt", // Typ 2
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawRect(canvas, x, y, w, h, COLOR_PLUGGED)
            }, {
                name: "Laden + Eingesteckt", // Typ 3
                visible: false,
                drawCallback: (canvas, x, y, w, h) => this.drawHatchedReversed(canvas, x, y, w, h, COLOR_CHARGING_PLUGGED)
            }, {
                name: "Freigegeben ohne Fahrzeug", // Typ 4
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, COLOR_ALLOWED)
            }, {
                name: "Freigegeben + Laden", // Typ 5
                visible: false,
                drawCallback: (canvas, x, y, w, h) => {
                    this.drawHatchedReversed(canvas, x, y, w, h, COLOR_ALLOWED_CHARGING);
                    this.drawHatched(canvas, x, y, w, h, COLOR_ALLOWED_CHARGING);
                }
            }, {
                name: "Eingesteckt & Freigegeben", // Typ 6
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, COLOR_ALLOWED_PLUGGED)
            }, {
                name: "Laden", // Typ 7
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, COLOR_ALLOWED_PLUGGED_CHARGING)
            }, {
                name: "Sonstige", // Typ default
                visible: false,
                drawCallback: (canvas, x, y, w, h) => this.drawRect(canvas, x, y, w, h, COLOR_ERROR)
            }, {
                name: "Jetzt",
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawNowMarker(canvas, x + w / 2 - 1, y, 2, h)
            }
        ];
    }

    async ngOnInit(): Promise<void> {
        return this.init(this.element);
    }

    private calcBackgroundSlots(
        alwSlots: GraphRange[],
        pluggedSlots: GraphRange[],
        chargingSlots: GraphRange[]
    ): Array<GraphRange & { type: number }> {
        const result: Array<GraphRange & { type: number }> = [];

        const changeTimestampsAlw = alwSlots.reduce((prev: number[], currentValue) => {
            prev.push(currentValue.start);
            prev.push(currentValue.end);
            return prev;
        }, []);
        const changeTimestampsPlugged = pluggedSlots.reduce((prev: number[], currentValue) => {
            prev.push(currentValue.start);
            prev.push(currentValue.end);
            return prev;
        }, []);
        const changeTimestampsCharging = chargingSlots.reduce((prev: number[], currentValue) => {
            prev.push(currentValue.start);
            prev.push(currentValue.end);
            return prev;
        }, []);

        let changeTimestamps = changeTimestampsAlw.concat(changeTimestampsCharging).concat(changeTimestampsPlugged);
        changeTimestamps.sort();
        changeTimestamps = changeTimestamps.filter((x, i) => x !== changeTimestamps[i - 1]);

        for (let i = 0; i < changeTimestamps.length - 1; i++) {
            const t = changeTimestamps[i] + 1;
            const type =
                (this.findState(alwSlots, t) ? 4 : 0) +
                (this.findState(pluggedSlots, t) ? 2 : 0) +
                (this.findState(chargingSlots, t) ? 1 : 0);
            result.push({
                start: changeTimestamps[i],
                end: changeTimestamps[i + 1],
                type
            });
        }

        return result;
    }

    private findState(slots: GraphRange[], time: number): boolean {
        return !!slots.find(s => time < s.end && time > s.start);
    }

    private getYValues(v: number | Date): string {
        if (this.selectedUnit === PowerUnits.W && (v as number) > 1000) {
            return ((v as number) / 1000).toFixed(1) + ' kW';
        }

        return (typeof v !== "number" ? v : v.toFixed(1)) + ' ' + PowerUnits[this.selectedUnit];
    }

    private getLegendLabels(): string[] {
        if (this.selectedUnit === PowerUnits.W) {
            return ['x',
                "Leistung (W)",
                "Events",
                "ICP (W)"
            ];
        } else {
            return ['x',
                "Strom I1 (A)",
                "Strom I2 (A)",
                "Strom I3 (A)",
                "Events",
                "ICP (A)"
            ];
        }
    }

    private getGraphColors(): string[] {
        if (this.selectedUnit === PowerUnits.W) {
            return [
                '#ffAA00',
                "#000000",
                "#CCCCCC"
            ];
        } else {
            return [
                '#ffAA00',
                '#ffBB00',
                '#ffCC00',
                "#000000",
                "#CCCCCC"
            ];
        }
    }
}

<div class="row">
  <div class="col-12">
    <h1 id="page-title">Profil</h1>
  </div>
  <div class="col">
    <mat-card appearance="outlined">
  <div class="row mb-5">
    <div id="img-container" class="col-5">
      <mat-icon class="igray" svgIcon="profil"></mat-icon>
    </div>
    <div class="col-7">
      <h2 class="c-blue-1">Hans Peter Petersen</h2>
      <form class="example-form">
        <mat-form-field class="full-width-field">
          <mat-label>Vorname</mat-label>
          <input matInput placeholder="Vorname" value="Hans Peter">
        </mat-form-field>
        <mat-form-field class="full-width-field">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" value="Petersen">
        </mat-form-field>
        <mat-form-field class="full-width-field">
          <mat-label>Email</mat-label>
          <input type="email" matInput placeholder="email" value="hans-peter@petersen.de">
        </mat-form-field>
        <mat-form-field class="full-width-field">
          <mat-label>Passwort</mat-label>
          <input type="password" matInput placeholder="Passwort" value="34134525636764645">
        </mat-form-field>
        <mat-form-field class="full-width-field">
          <mat-label>Passwort wiederholen</mat-label>
          <input type="password" matInput placeholder="Passwort wiederholen" value="34134525636764645">
        </mat-form-field>
        <button id="btn-login" mat-raised-button color="primary" type="submit">Aktualisieren</button>
      </form>
    </div>
  </div>
</mat-card>
</div>
</div>
